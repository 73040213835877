// tslint:disable:no-shadowed-variable
import {VideojsAnalyticsStateMachine} from '../../analyticsStateMachines/VideoJsAnalyticsStateMachine';
import VideoCompletionTracker from '../../core/VideoCompletionTracker';
import {Event} from '../../enums/Event';
import {getMIMETypeFromFileExtension} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {getStreamTypeFromMIMEType} from '../../enums/StreamTypes';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {StreamSources} from '../../types/StreamSources';
import {isDifferentSubtitleInfo, SubtitleInfo} from '../../types/SubtitleInfo';
import {InternalAdapter} from './InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class VideojsInternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  public readonly videoCompletionTracker: VideoCompletionTracker;
  private _subtitleInfo: SubtitleInfo = {enabled: false};
  private selectedAudioLanguage?: string;
  private onBeforeUnLoadEvent: boolean = false;
  private previousTime: number = 0;

  constructor(private player: videojs.default.Player, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.stateMachine = new VideojsAnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
    this.videoCompletionTracker = new VideoCompletionTracker();
  }

  public initialize() {
    this.register();
  }

  public getPlayerVersion = () => ((window as any).videojs ? (window as any).videojs.VERSION : 'unknown');
  public getPlayerName = () => Player.VIDEOJS;
  public getPlayerTech = () => 'html5';
  public getAutoPlay = () => (this.player.autoplay() as any) == true; // tslint:disable-line:triple-equals
  public getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  public getStreamType(url: string) {
    const mimeType = getMIMETypeFromFileExtension(url);
    if (mimeType) {
      return getStreamTypeFromMIMEType(mimeType);
    }
  }

  // this seems very generic. one could put it in a helper
  // and use it in many adapter implementations.
  public getStreamSources(url: string): StreamSources {
    const streamType = this.getStreamType(url);
    switch (streamType) {
      case 'hls':
        return {m3u8Url: url};
      case 'dash':
        return {mpdUrl: url};
      default:
        return {progUrl: url};
    }
  }

  public getCurrentPlaybackInfo(): PlaybackInfo {
    this.selectedAudioLanguage = this.getSelectedAudioTrackLanguage(this.player);

    const info: PlaybackInfo = {
      ...this.getCommonPlaybackInfo(),
      ...this.getStreamSources(this.player.currentSrc()),
      streamFormat: this.getStreamType(this.player.currentSrc()),
      isLive: this.player.duration() === Infinity,
      size: (this.player as any).isFullscreen() ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      isMuted: this.player.muted(),
      videoDuration: this.player.duration(),
      videoWindowHeight: this.player.height(),
      videoWindowWidth: this.player.width(),
      videoPlaybackHeight: (this.player as any).videoHeight(),
      videoPlaybackWidth: (this.player as any).videoWidth(),
      audioLanguage: this.selectedAudioLanguage,
      subtitleEnabled: this._subtitleInfo.enabled,
      subtitleLanguage: this._subtitleInfo.language,
      droppedFrames: 0, // TODO
      // TODO audioBitrate:
      // TODO isCasting:
      // TODO videoTitle: (currently only from the analytics config)
    };

    const qualityInfo = this.getCurrentQualityLevelInfo();
    if (qualityInfo) {
      info.videoPlaybackWidth = qualityInfo.width || info.videoPlaybackWidth;
      info.videoPlaybackWidth = qualityInfo.height || info.videoPlaybackHeight;
      info.videoBitrate = qualityInfo.bitrate;
    }

    return info;
  }

  public getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    // TODO: Needs to be implemented
    return null;
  }

  public register() {
    const that = this;
    this.player.on('loadedmetadata', function (this: any) {
      that.videoCompletionTracker.reset();
      that.videoCompletionTracker.setVideoDuration(this.duration());
      that.previousTime = 0;
      that.eventCallback(Event.SOURCE_LOADED, {});
    });
    this.player.ready(function (this: any) {
      that._subtitleInfo = that.getSubtitleInfo(this);
      that.eventCallback(Event.READY, {});
    });
    this.player.on('play', function (this: any) {
      that.eventCallback(Event.PLAY, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('pause', function (this: any) {
      if (!this.seeking()) {
        that.eventCallback(Event.PAUSE, {
          currentTime: this.currentTime(),
        });
      }
    });
    this.player.on('error', function (this: any) {
      const error = this.error();
      that.eventCallback(Event.ERROR, {
        currentTime: this.currentTime(),
        code: error.code,
        message: error.message,
      });
    });
    this.player.on('volumechange', function (this: any) {
      const muted = this.muted();
      if (muted) {
        that.eventCallback(Event.MUTE, {
          currentTime: this.currentTime(),
        });
      } else {
        that.eventCallback(Event.UN_MUTE, {
          currentTime: this.currentTime(),
        });
      }
    });
    this.player.on('seeking', function (this: any) {
      that.eventCallback(Event.SEEK, {
        currentTime: that.previousTime != null ? that.previousTime : this.currentTime(),
      });
    });
    this.player.on('seeked', function (this: any) {
      that.eventCallback(Event.SEEKED, {
        currentTime: this.currentTime(),
      });
    });

    this.player.on('texttrackchange', function (this: any) {
      const newSubtitleInfo = that.getSubtitleInfo(this);
      const hasInfoChanged =
        that._subtitleInfo == null ? true : isDifferentSubtitleInfo(that._subtitleInfo, newSubtitleInfo);
      that._subtitleInfo = newSubtitleInfo;

      if (hasInfoChanged) {
        that.eventCallback(Event.SUBTITLE_CHANGE, {
          currentTime: this.currentTime(),
        });
      }
    });

    const audioTracks = (this.player as any).audioTracks();
    audioTracks.on('change', () => {
      const newSelectedAudioLanguage = that.getSelectedAudioTrackLanguage(that.player);
      if (newSelectedAudioLanguage != null && newSelectedAudioLanguage !== that.selectedAudioLanguage) {
        that.eventCallback(Event.AUDIOTRACK_CHANGED, {
          currentTime: that.player.currentTime(),
        });
      }
    });

    this.player.on('stalled', function (this: any) {
      that.eventCallback(Event.START_BUFFERING, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('waiting', function (this: any) {
      that.eventCallback(Event.START_BUFFERING, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('timeupdate', function (this: any) {
      if (!this.seeking()) {
        that.previousTime = this.currentTime();
      }
      that.eventCallback(Event.TIMECHANGED, {
        currentTime: this.currentTime(),
      });

      // that is not the quality that is currently being played.
      // for more accuracy one can use the segment-metadata cue tracking:
      // https://github.com/videojs/videojs-contrib-hls#segment-metadata
      const selectedPlaylist = this.tech_.hls ? this.tech_.hls.playlists.media() : null;
      if (!selectedPlaylist) {
        return;
      }

      const {attributes} = selectedPlaylist;
      const bitrate = attributes.BANDWIDTH;
      const width = (attributes.RESOLUTION || {}).width;
      const height = (attributes.RESOLUTION || {}).height;

      if (that.shouldAllowVideoQualityChange(bitrate)) {
        const eventObject = {
          width,
          height,
          bitrate,
          currentTime: this.currentTime(),
        };

        that.eventCallback(Event.VIDEO_CHANGE, eventObject);
        that.setPreviousVideoBitrate(bitrate);
      }

      // Check for HLS source-handler (videojs-contrib-hls)
      // When we just use Videojs without any specific source-handler (not using MSE API based engine)
      // but just native technology (HTML5/Flash) to do for example "progressive download" with plain Webm/Mp4
      // or use native HLS on Safari this may not not be present. In that case Videojs is just
      // a wrapper around the respective playback tech (HTML or Flash).

      const tech = this.tech({IWillNotUseThisInPlugins: true});
      if (tech.hls) {
        // From here we are going onto Videojs-HLS source-handler specific API
        //
        const hls = this.tech_.hls;

        // Maybe we have the HLS source-handler initialized, but it is
        // not actually activated and used (just wrapping HTML5 built-in HLS playback like in Safari)
        if (!hls.playlists || typeof hls.playlists.media !== 'function') {
          return;
        }

        // Check for current media playlist
        const selectedPlaylist = hls.playlists.media();
        if (!selectedPlaylist) {
          return;
        }

        const {attributes} = selectedPlaylist;
        const bitrate = attributes.BANDWIDTH;
        const width = (attributes.RESOLUTION || {}).width;
        const height = (attributes.RESOLUTION || {}).height;

        // update actual bitrate
        if (that.shouldAllowVideoQualityChange(bitrate)) {
          const eventObject = {
            width,
            height,
            bitrate,
            currentTime: this.currentTime(),
          };

          that.eventCallback(Event.VIDEO_CHANGE, eventObject);
          that.setPreviousVideoBitrate(bitrate);
        }
      }
    });

    const handlePageClose = () => {
      if (!this.onBeforeUnLoadEvent) {
        this.onBeforeUnLoadEvent = true;
        let currentTime: number | undefined;
        if (this.player != null) {
          currentTime = this.player.currentTime();
        }
        this.eventCallback(Event.UNLOAD, {
          currentTime,
        });
      }
    };

    window.addEventListener('beforeunload', handlePageClose);
    window.addEventListener('unload', handlePageClose);
  }

  public sourceChange(config: any, timestamp: number) {
    this.videoCompletionTracker.reset();
    this.videoCompletionTracker.setVideoDuration(this.player.duration());
    this.previousTime = 0;
    this.stateMachine.sourceChange(config, timestamp, this.player.currentTime());
  }

  private getSelectedAudioTrackLanguage(player: any): string | undefined {
    for (const track of player.audioTracks()) {
      if (track.enabled) {
        return track.language;
      }
    }
    return undefined;
  }

  private getSubtitleInfo(player: any): SubtitleInfo {
    const textTracks = player.textTracks() || [];
    let enabled = false;
    let language;
    for (const track of textTracks) {
      if (track.mode === 'showing') {
        enabled = true;
        language = track.language;
        break;
      }
    }
    return {
      enabled,
      language,
    };
  }
}
